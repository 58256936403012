@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
.Loading_block__2wyLv{position:absolute;top:0;left:0;right:0;bottom:0;background-color:rgba(255,255,255,0.4);display:flex;align-items:center;justify-content:center;z-index:3}

.LoadingLinearProgress_linear__32jld{position:fixed !important;top:-10px;height:7px;left:0;z-index:1000;background:linear-gradient(to right, transparent, #fff);transform:translateX(-100%);animation:LoadingLinearProgress_progress-bar-shine__2z5aT 2s infinite}@keyframes LoadingLinearProgress_progress-bar-shine__2z5aT{to{transform:translateX(0);opacity:1}}

body{--c-primary_rgb: 60, 110, 255;--c-primary: rgb(var(--c-primary_rgb));--c-secondary: #23cf8c;--c-error: #fc6183;--c-warn: #fcb561;--ctrls-text-c: #96a1a9;--ctrls-border-c: #D5E0E7;--ctrls-border-c_F: #A0ADB6;--ctrls-bg-c: white;--ctrls-text-c_D: rgba(0, 0, 0, 0.25);--ctrls-bg-c_D: #f7fafc;--ctrls-text-_A: white;--ctrls-bg-_A: var(--btn-primary-bg);--ctrls-bg-selected: var(--c-primary);--ctrls-panel-bg: var(--c-primary);--ctrls-panel-c: white;--layout-content-bg: #F8F9FD;--grad-main: linear-gradient( 139deg,
    rgba(var(--c-primary_rgb), 1) 0%,
    rgba(var(--c-primary_rgb), .9) 50%,
    rgba(var(--c-primary_rgb), .8) 90%,
    rgba(var(--c-primary_rgb), .6) 100%);--grad-progress: linear-gradient( 90deg, var(--c-primary) 0%, #783294 100%);--c-t: #515963;--c-t-heading: #515963;--c-t-highlight: var(--c-primary);--c-t-highlight-d: var(--c-t-heading);--c-t-ignore: #9ca7af;--c-success: green;--btn-default-bg: rgb(255, 255, 255);--btn-default-text: #63768E;--btn-default-border: rgb(229, 233, 238);--btn-default-border_H: rgb(207, 213, 220);--btn-primary-bg: linear-gradient( -90deg, rgba(var(--c-primary_rgb), 1) 0%, rgba(var(--c-primary_rgb), .7) 100%);--btn-primary-text: white;--btn-primary-bg_H: var(--c-primary);--btn-primary-text_H: white;--btn-secondary-bg: #23cf8c;--btn-secondary-text: white;--btn-secondary-bg_H: #3fe1a2;--btn-secondary-text_H: white;--btn-ignore-bg: #454F5B;--btn-ignore-text: white;--btn-ignore-bg_H: #737B85;--btn-ignore-text_H: white;--btn-link-text: #878E98;--btn-link-text_H: var(--c-primary);--t: .3s ease;--form-item-height: 46px}*{-webkit-tap-highlight-color:transparent}#root{height:100vh;width:100vw;overflow:hidden}html{font-size:14px;line-height:1}html body{font-size:1rem;font-style:normal;font-family:"Lato";font-weight:500;letter-spacing:.5px}html body h1,html body h2,html body h3,html body h4,html body h5,html body h6{font-weight:700;color:var(--c-t-heading)}html body h1{font-size:1.7rem}html body h2{font-size:1.5rem}html body h3{font-size:1.3rem}html body h4{font-size:1.1rem}html body h5{font-size:.9rem}.react-draggable-transparent-selection{-webkit-user-select:none;-ms-user-select:none;user-select:none}a,.link{color:#1890ff;text-decoration:none;background-color:transparent;cursor:pointer;transition:all var(--t)}a:hover,.link:hover{color:rgba(24,144,255,0.69)}.sb-avatar{font-family:inherit !important}.sb-avatar .sb-avatar__text{line-height:.8 !important}.ant-form-item-control{line-height:inherit}.ant-layout{overflow:hidden}.ant-form-item-row{display:block}

