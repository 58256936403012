*{
  -webkit-tap-highlight-color: transparent;
  //outline: 1px solid red;
}
#root{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
html{
  font-size: 14px;
  line-height: 1;
}
html body{
  font: {
    size: 1rem;
    style: normal;
    family: "Lato";
    weight: 500;
  }
  letter-spacing: .5px;

  h1, h2, h3, h4, h5, h6{
    font-weight: 700;
    color: var(--c-t-heading);
  }
  h1{
    font-size: 1.7rem;
  }
  h2{
    font-size: 1.5rem;
  }
  h3{
    font-size: 1.3rem;
  }
  h4{
    font-size: 1.1rem;
  }
  h5{
    font-size: .9rem;
  }
}



.react-draggable-transparent-selection{
  user-select: none;
}
a,
.link{
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  transition: all var(--t);
  &:hover{
    color: rgba(24, 144, 255, 0.69);
  }
}
.sb-avatar{
  font-family: inherit !important;
  .sb-avatar__text{
    line-height: .8 !important;
  }
}
