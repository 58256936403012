body{

  --c-primary_rgb: 60, 110, 255;
  --c-primary: rgb(var(--c-primary_rgb));

  --c-secondary: #23cf8c;
  //--c-main: #454f5b;
  //--c-main: #f7fafc;
  --c-error: #fc6183;
  --c-warn: #fcb561;
  //--c-main: #9a60d0;

  --ctrls-text-c: #96a1a9;
  --ctrls-border-c: #D5E0E7;
  --ctrls-border-c_F: #A0ADB6;
  --ctrls-bg-c: white;
  --ctrls-text-c_D: rgba(0, 0, 0, 0.25);
  --ctrls-bg-c_D: #f7fafc;
  --ctrls-text-_A: white;
  --ctrls-bg-_A: var(--btn-primary-bg);

  --ctrls-bg-selected: var(--c-primary);
  --ctrls-panel-bg: var(--c-primary);
  --ctrls-panel-c: white;

  --layout-content-bg: #F8F9FD;


  --grad-main: linear-gradient( 139deg,
    rgba(var(--c-primary_rgb), 1) 0%,
    rgba(var(--c-primary_rgb), .9) 50%,
    rgba(var(--c-primary_rgb), .8) 90%,
    rgba(var(--c-primary_rgb), .6) 100%);

  //--grad-main: linear-gradient( 139deg, rgb(80,76,220) 0%, rgb(54,119,215) 100%);

  --grad-progress: linear-gradient( 90deg, var(--c-primary) 0%, #783294 100%);

  --c-t: #515963; /* t-text */
  --c-t-heading: #515963;
  --c-t-highlight: var(--c-primary);
  --c-t-highlight-d: var(--c-t-heading);
  --c-t-ignore: #9ca7af;

  --c-success: green;

  --btn-default-bg: rgb(255, 255, 255);
  --btn-default-text: #63768E;
  --btn-default-border: rgb(229, 233, 238);
  --btn-default-border_H: rgb(207, 213, 220);

  --btn-primary-bg: linear-gradient( -90deg, rgba(var(--c-primary_rgb), 1) 0%, rgba(var(--c-primary_rgb), .7) 100%);
  --btn-primary-text: white;
  --btn-primary-bg_H: var(--c-primary);
  --btn-primary-text_H: white;

  --btn-secondary-bg: #23cf8c;
  --btn-secondary-text: white;
  --btn-secondary-bg_H: #3fe1a2;
  --btn-secondary-text_H: white;

  --btn-ignore-bg: #454F5B;
  --btn-ignore-text: white;
  --btn-ignore-bg_H: #737B85;
  --btn-ignore-text_H: white;

  --btn-link-text: #878E98;
  --btn-link-text_H: var(--c-primary);

  --t: .3s ease;

  --form-item-height: 46px;
}
