.linear {
  position: fixed !important;
  top: -10px;
  height: 7px;
  left: 0;
  z-index: 1000;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 1));
  transform: translateX(-100%);
  animation: progress-bar-shine 2s infinite;
}

@keyframes progress-bar-shine {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

